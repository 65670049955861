<template>
  <div class="occupancy-block">
    <div class="occupancy-block__wrap">
      <span v-html="'Per'" />
      <occupancy-icons
          v-b-popover.hover.top="block_occupancy.tooltip"
          :occupancy="{
            adults: block_occupancy.adults,
            children: block_occupancy.children,
          }"
      />
      <vb-icon class="occupancy-block__icon occupancy-block__icon--info ml-1" :name="'info'" :color="'adb5bd'" :size="13" v-b-popover.hover.top="block_occupancy.tooltip" />
    </div>
  </div>
</template>

<script>
import {min, add, subtract} from "mathjs"
import OccupancyIcons from '@booking/components/atoms/occupancy_icons.vue'

export default {
  name: 'occupancy-block',

  components: {
    'occupancy-icons': OccupancyIcons,
  },

  props: {
    block: Object,
    occupancy: Array
  },

  data() {
    return {
      block_occupancy: this.get_block_occupancy(this.block)
    }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
    get_block_occupancy(block) {

      /* doc analisi:
        https://docs.google.com/document/d/16F9s-W6VBLhq-enVrzo2Otg8sSOQrMx6vSrataKKTec/edit
        https://docs.google.com/document/d/1CbBUUYUwusTPjFjwnfVw7biGTKZgFGP1qoCeGG1rZkg/edit
      */

      let occupancy_block = { adults: block.max_adults, children: 0, tooltip: '' }

      /* info del blocco */
      const { max_adults, max_children_free, max_children_free_age, max_occupancy, block_id } = block,
            is_child_block = !block_id.endsWith("_0"),

            // totale occupancy
            total_occupants = this.occupancy.reduce((total, current) => {
              return total + current.adults + current.children.length
            }, 0)

      let age_children = [],  // mi serve per la gestione tooltip del child_block
          total_childs = 0    // totale bambini della ricerca


      if (max_children_free > 0) {
        this.occupancy.forEach(pax => {
          pax.children.forEach(age => {
            total_childs = add(total_childs, 1)

            // match dei bambini che rientrano nell'età e nella capienza del blocco
            if (age <= max_children_free_age && occupancy_block.children < max_children_free)
              occupancy_block.children = add(occupancy_block.children, 1)
          })
        })
      }

      /* REGULAR BLOCK - se l'eta massima dei bambini del blocco è uguale a zero oppure il max_adults è maggiore o uguale
      al totale della occupancy ricercata, non stampo icone bambino */
      if (!is_child_block && (max_children_free_age === 0 || max_adults >= total_occupants))
        occupancy_block.children = 0


      /* CHILD BLOCK - il child_block in generale deve sempre stampare un'icona bambino, tenendo conto dei
      bambini matchati e della max_occupancy */
      if(is_child_block){

        if(add(max_adults, occupancy_block.children) < max_occupancy && total_childs > occupancy_block.children)
          occupancy_block.children = add(occupancy_block.children, 1)

        //if (max_children_free === 0 && max_children_free_age === -1 && max_adults < max_occupancy) occupancy_block.children = 1
      }


      /* Regole che valgono per entrambi le tipologie di blocco (REGULAR e CHILD BLOCK) */

      // riduco le icone bambino se la somma di max_adults e max_children_free è maggiore della occupancy
      if ((add(max_adults, max_children_free)) > max_occupancy) {
        let diff_mo_ma = subtract(max_occupancy, max_adults)
        occupancy_block.children = min(occupancy_block.children, diff_mo_ma)
      }

      // se max_occupancy e il max_adults sono uguali non devo stampare l'icona bambino
      if(max_occupancy === max_adults) occupancy_block.children = 0

      /* ------------ */


      /* Tooltip  */

      if(is_child_block) {
        age_children = this.occupancy
            .flatMap(item => item.children)
            .sort((a, b) => a - b)
            .slice(0, occupancy_block.children)
      }

      occupancy_block.tooltip  = `soluzione per ${block.max_adults} ${block.max_adults > 1 ? 'adulti' : 'adulto'}`

      if (occupancy_block.children > 0) {
        let child_text = `${occupancy_block.children} ${occupancy_block.children > 1 ? 'bambini' : 'bambino'} `
        let age_text = age_children.length ? `di ${age_children}` : `fino a ${block.max_children_free_age}`
        let year_text = block.max_children_free_age || (age_children.length > 1 || age_children !== 1) ? 'anni' : 'anno'
        occupancy_block.tooltip  += ` e ${child_text}${age_text} ${year_text}`
      } else {
        occupancy_block.tooltip  += ` o ${block.max_adults} ${block.max_adults > 1 ? 'ospiti' : 'ospite'}`
      }

      this.$emit('badge-occupancy', block_id, occupancy_block.tooltip)

      return occupancy_block
    }    
  },
}
</script>

<style lang="scss" scoped>

  .occupancy-block {
    $this_occupancy: &;

    &__wrap {
      display: flex;
      align-items: center;
    }
  }

</style>