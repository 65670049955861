<template>
  <div v-if="hotel.salabamScore > 0" class="score" :class="direction === 'row' && 'score--row'">
    <span class="score__number">
      <span v-html="hotel.salabamScore % 1 === 0 ? round(hotel.salabamScore, 0) : round(hotel.salabamScore, 1)" />
    </span>
    <span class="score__rank" v-html="custom_textual_score(hotel.salabamScore)" />
  </div>
</template>

<script>
import { round } from 'mathjs'
export default {
  name: 'score',

  components: {
  },

  props: {
    hotel: Object,
    direction: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
    }
  },

  computed: {
    custom_textual_score() {
      return this.textual_score()
    },
  },

  created() {
    this.round = round
  },

  mounted() {
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

  .score {
    $this: &;
    font-size: $font-size-md;
    position: absolute;
    top: 10px;
    z-index: 1;
    right: 10px;
    background: $white;
    padding: 3px 5px;
    border-radius: 5px;

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 20%;
      text-align: center;
      position: static;
      background: transparent;
      padding: 0;

      &--row {
        align-items: center;
        flex-direction: row;
      }
    }

    &__number {
      color: $secondary;
      font-weight: $vb-font-weight-semibold;

      @include media-breakpoint-up(lg) {

        @at-root #{$this}--row & {
          order: 1;
          margin-left: 10px
        }
      }

      span {
        @include media-breakpoint-up(lg) {
          display: inline-block;
          border-radius: 50%;
          padding: 5px;
          background-color: $secondary;
          color: $white;
          width: 35px;
          height: 35px;
        }
      }
    }

    &__rank {
      font-weight: $vb-font-weight-semibold;
      margin-left: 5px;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }
  }

</style>